//import * as Vue from 'vue'
import { createRouter, createWebHistory } from 'vue-router';
import store from '../store'; // Import the Vuex store
import MasterLayout from '@/views/MasterLayout.vue';
import NotFoundLayout from '@/views/NotFoundLayout.vue';
import { _keycloak } from '@/plugins/authentication';
import HomePage from '@/views/HomePage.vue'; // Adjust the import according to your structure
import NotFound from '@/views/NotFound.vue'; // Import the 404 component
import AppMyFirms from '@/views/AppMyFirms.vue';
//import AppFirms from '@/views/AppFirms.vue';
import AppMyInvestmentProfessionals from '@/views/AppMyInvestmentProfessionals.vue';
import AppProfiles from '@/views/AppProfiles.vue';
import EmailsWeeklyEmails from '@/views/EmailsWeeklyEmails.vue';
import AppWeeklyEmailDateRange from '@/views/AppWeeklyEmailDateRange.vue';
import EmailFirmSearchResults from '@/views/EmailFirmSearchResults.vue';
import EmailFirmSearchExecutives from '@/views/EmailFirmSearchExecutives.vue';
import TempPage from '@/views/TempPage.vue';
import UserSettings from '@/views/UserSettings.vue';
import DjangoApiLogin from '../views/DjangoApiLogin.vue';

const routes = [
  {
    path: '/',
    component: MasterLayout,
    children: [
      {
        path: '',
        component: HomePage,
        name: 'HomePage',
        meta: {
          isAuthenticated: true,
          title: 'Home Page',
          description: 'This is the home page'
        }
      },
      {
        path: '/django-api-login',
        component: DjangoApiLogin,
        name: 'DjangoApiLogin',
        meta: {
          isAuthenticated: true,
          title: 'Django Api Login',
          description: ''
        }
      },
      // {
      //   path: '/app-firms',
      //   component: AppFirms,
      //   name: 'AppFirms',
      //   meta: {
      //     isAuthenticated: true,
      //     title: 'App Firms',
      //     description: 'This is the Firms'
      //   }
      // },
      {
        path: '/app-my-firms',
        component: AppMyFirms,
        name: 'AppMyFirms',
        meta: {
          isAuthenticated: true,
          title: 'App My Firms',
          description: 'This is the My Firms'
        }
      },
      {
        path: '/app-my-investment-professionals',
        component: AppMyInvestmentProfessionals,
        name: 'AppMyInvestmentProfessionals',
        meta: {
          isAuthenticated: true,
          title: 'App My Investment Professionals',
          description: 'This is the My Investment Professionals'
        }
      },
      {
        path: '/app-profiles',
        component: AppProfiles,
        name: 'AppProfiles',
        meta: {
          isAuthenticated: true,
          title: 'App Profiles',
          description: 'This is the Profiles'
        }
      },
      {
        path: '/emails/weekly-emails-dates',
        component: AppWeeklyEmailDateRange,
        name: 'AppWeeklyEmailDateRange',
        meta: {
          isAuthenticated: true,
          title: 'Weekly Emails Select Date Range',
          description: 'This is the Select Date Range'
        }
      },
      {
        path: '/emails/weekly-emails',
        component: EmailsWeeklyEmails,
        name: 'EmailsWeeklyEmails',
        meta: {
          isAuthenticated: true,
          title: 'Weekly Emails',
          description: 'This is the Weekly Emails'
        }
      },
      {
        path: '/emails/weekly-emails-search-executives/:profile_id',
        component: EmailFirmSearchExecutives,
        name: 'EmailsWeeklySearchExecs',
        meta: {
          isAuthenticated: true,
          title: 'Weekly Emails',
          description: 'This is the Weekly Emails'
        }
      },
      {
        path: '/temp',
        component: TempPage,
        name: 'TempRoute',
        meta: {
          isAuthenticated: true,
          title: 'Temp Page',
          description: 'This is the Temp Page'
        }
      },
      {
        path: '/emails/weekly-emails/firm-search-results/:firmId',
        component: EmailFirmSearchResults,
        name: 'EmailFirmSearchResults',
        meta: {
          isAuthenticated: true,
          title: 'Firm Search Results',
          description: 'Results for the selected firm'
        }
      },
      {
        path: '/user-settings/',
        component: UserSettings,
        name: 'UserSettings',
        meta: {
          isAuthenticated: true,
          title: 'User Settings',
          description: 'This is the Settings'
        }
      },
    ]
  },
  {
    path: '/:pathMatch(.*)*', // Catch-all route
    component: NotFoundLayout, // Use a different layout for NotFound
    children: [
      {
        path: '',
        component: NotFound,
        meta: {
          isAuthenticated: false,
          title: '404',
          description: 'Oops! The page you\'re looking for doesn\'t exist.'
        }
      }
    ]
  }
];


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

/*router.afterEach((to) => {
  Vue.nextTick(() => {
    console.log("TOO:" + JSON.stringify(to.meta.description, null, 2));
    const titlePrefix = 'CEO Discovery | ';
    document.title = titlePrefix + to.meta.title;
    
    // document.title = to.meta.title(to);
  });
});*/
router.beforeEach(async (to, from, next) => {
  if (to.meta.isAuthenticated) {
    
    console.log("KEYCLOAK REDIRECTED..." );

    // Get the actual url of the app, it's needed for Keycloak  
    const basePath = window.location.toString();
    if (!_keycloak.authenticated) {
      // The page is protected and the user is not authenticated. Force a login.
      _keycloak.login({ redirectUri: basePath });//NAEEM
      //console.log("KEYCLOAK REDIRECTED..." + _keycloak + "||" +basePath);
    } else {
      //await updateToken();
      const isAuthenticated = store.getters.isAuthenticated;
      //console.log("NNNNN:"+isAuthenticated);
      if (to.name !== 'DjangoApiLogin' && !isAuthenticated) {
        next({ name: 'DjangoApiLogin' }); // Redirect to login page if not authenticated
      } else {
        next(); // Proceed to the route
      }
    }
    if(!from.name){ return false}
  } else {
    next(); // Proceed to the route
  }
  
});

export default router;