<template>
  <div class="w-100">
    <BreadcrumbNav :crumbs="breadcrumbs" />
    <WeeklyDateRangeDisplay :isReload="flase" />
    <div class="data-list">
      <div v-for="group in groupedResults" :key="group.userId" class="row align-items-center mb-1 p-2 bg-light">
        <div class="col text-start" :data-id="group.userId">{{ getUserName(group.userId) }}</div>
        <div class="col text-left">Count: {{ group.count }}</div>
        <div class="col text-end">
          <button class="btn btn-secondary" @click="searchExecs(group.userId)">Search Execs</button> &nbsp; 
          <button class="btn btn-primary" @click="showAllChildren(group.userId)">View All</button>
        </div>
      </div>
    </div>
    <!-- All Children Modal -->
    <div v-if="showAllChildrenModal" :class="showAllChildrenModal ? 'modal modal-show' : 'modal'">
      <div class="modal-content">
        <span class="close" @click="hideModals">&times;</span>
        <h5>All Children</h5>
        <pre>{{ childrenData }}</pre>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, getCurrentInstance, watchEffect, computed} from 'vue';
import { useQuery } from '@urql/vue';
import { APP_FIRM_SEARCH_RESULTS_BY_FIRM_ID } from '../graphql/firms_queries';
import WeeklyDateRangeDisplay from '../components/WeeklyDateRangeDisplay.vue';
import BreadcrumbNav from '@/components/BreadCrumbNav.vue';
import sessionCheckMixin from '../mixins/CheckWeeklyDateSession';
//import apiService from '@/services/apiService'; // Adjust the import path as needed

export default defineComponent({
  name: 'EmailFirmSearchResults',
  components: {
    WeeklyDateRangeDisplay,
    BreadcrumbNav
  },
  mixins: [sessionCheckMixin],
  setup() {
    const firmId = ref('');
    const firm_name = ref('');
    const searchResults = ref([]);
    const totalItems = ref(0);
    const loading = ref(false);
    const childrenData = ref({});
    const showAllChildrenModal = ref(false);
    const userNames = reactive({});
    const breadcrumbs = [
      { text: 'Home', routerName: 'HomePage' },
      { text: 'Weekly Emails', routerName: 'EmailsWeeklyEmails' },
      { text: 'Search', routerName: '' },
    ];
    loading.value = true;
    

    const groupedResults = computed(() => {
      const grouped = searchResults.value.reduce((acc, curr) => {
        if (!acc[curr.savedBy.userId]) {
          acc[curr.savedBy.userId] = { userId: curr.savedBy.userId, count: 0, children: [] };
        }
        acc[curr.savedBy.userId].count++;
        acc[curr.savedBy.userId].children.push(curr);
        return acc;
      }, {});
      return Object.values(grouped);
    });

    const fetchSearchResults = async () => {
      const { data, error } = useQuery({query: APP_FIRM_SEARCH_RESULTS_BY_FIRM_ID, variables: {firm_id: firmId.value}});
      watchEffect(() => {
        if (data.value) {
          searchResults.value = data.value.appFirmSearchResultsByFirmId.searchRecords;
          console.log(data.value.appFirmSearchResultsByFirmId)
          firm_name.value = data.value.appFirmSearchResultsByFirmId.firmName;
          document.getElementById('header-title').innerText = `Weekly Emails - ${firm_name.value}`;
          totalItems.value = searchResults.value.length;

          const userIdToName = data.value.appFirmSearchResultsByFirmId.userIdToName;
          userIdToName.forEach(user => {
            userNames[user.id] = user.name.fullName;
          });
          loading.value = false;
        }
        if (error.value) {console.error('Error fetching data:', error.value);loading.value = false;}
      });
    };

    const getUserName = (userId) => {
      return userNames[userId] || 'Loading...';
    };

    const showAllChildren = (userId) => {
      // console.log("UserID:"+userId);
      const group = groupedResults.value.find(group => group.userId === userId);
      // console.log(group);
      if (group) {
        childrenData.value = group.children;
        showAllChildrenModal.value = true;
        // onsole.log("VALE::");
        // console.log(childrenData.value);
        // console.log("Model:"+showAllChildrenModal.value);
      }
    };

    const hideModals = () => {
      showAllChildrenModal.value = false;
    };

    const { proxy } = getCurrentInstance();
    const searchExecs = (profile_id) => {
      // Assuming you have a router instance available in the setup function
      console.log("ss:"+profile_id);
      proxy.$router.push({ name: 'EmailsWeeklySearchExecs', params: { profile_id } });
    };

    const checkFirmIdQueryParam = () => {
      if (firmId.value) {
        fetchSearchResults();
      } else {
        console.warn('No firm_id query parameter found');
      }
    };

    // Fetch firmId from route params
    firmId.value = proxy.$route.params.firmId;

    checkFirmIdQueryParam();

    return {
      firmId,
      firm_name,
      searchResults,
      loading,
      childrenData,
      showAllChildrenModal,
      userNames,
      breadcrumbs,
      groupedResults,
      fetchSearchResults,
      //fetchUserName,
      getUserName,
      showAllChildren,
      hideModals,
      searchExecs,
      checkFirmIdQueryParam
    };
  }
});
</script>
