<template>
  <main>
      <div class="container-fluid">
        <div class="row header-row">
            <!-- First Column -->
            <div class="col-3 column-1 main-left-column bg-primary">
                <div class="logo">
                    <router-link class="" :to="{name: 'HomePage'}" ><img src="../assets/images/CEODiscovery-logo.png" alt="Logo" class="img-fluid"></router-link>
                </div>
                <div class="nav-links">
                    <nav class="nav flex-column">
                        <router-link class="nav-link" :to="{name: 'HomePage'}" >Home</router-link>
                        <router-link class="nav-link child-link" :to="{name: 'AppMyFirms'}" >My Firms</router-link>
                        <router-link class="nav-link" :to="{name: 'AppMyInvestmentProfessionals'}" >My Investment Professionals</router-link>
                        <router-link class="nav-link" :to="{name: 'AppProfiles'}" >Profles</router-link>
                        <router-link class="nav-link" :to="{name: 'AppWeeklyEmailDateRange'}" >Weekly Emails</router-link>
                    </nav>
                </div>
                <div class="bottom-links">
                    <p class="text-white" >{{ email }} ({{ username }})</p>
                    <router-link class="nav-link" :to="{name: 'UserSettings'}" ><i class="fas fa-cog"></i> Settings</router-link>
                    <br>
                    <button v-if="username === 'naeemnn'" @click="checkToken">Check Token</button>
                    <button @click.prevent="logout">Logout</button>
                </div>
            </div>
            <!-- Second Column -->
            <div class="col-9 column-2 p-4">
                <HeaderApp :title="headerTitle" />
                <router-view />
                <FooterApp :footerText="footerText" />
            </div>
        </div>
    </div>
  </main>
</template>

<script>
import { computed, defineComponent } from 'vue';

import { useHead } from '@vueuse/head';
import { useRoute } from 'vue-router';
import HeaderApp from '@/components/HeaderApp.vue';
import FooterApp from '@/components/FooterApp.vue';
//import customCSS from '@/assets/css/custom.css';

const ceoDiscoverySymbol = new URL('@/assets/images/CEODiscovery_Symbol.svg', import.meta.url).href;
//const CEOCustomCSS = new URL('@/assets/css/custom.css', import.meta.url).href;

/*const CEOCustomCSS = new URL('@/assets/css/custom.css', import.meta.url).href;
const CEOCustomCSS2 = new URL('@/assets/css/custom.css', import.meta.url);

console.log("META URL:"+import.meta.url);
console.log("META URL CEOCustomCSS:"+CEOCustomCSS);
console.log("META URL CEOCustomCSS2:"+CEOCustomCSS2);
console.log("META URL 2:"+'@/assets/css/custom.css');*/

export default defineComponent({
  data() {
    return {
      username: this.$username,
      email: this.$email
    };
  },
  components: {
    HeaderApp,
    FooterApp
  },
  methods: {
    logout() {
      this.$keycloak.logout({
        redirectUri: window.location.origin
      });
    },
    checkToken() {
      console.log("Access Token:"+this.$store.state.accessToken);
    }
  },
  setup() {
    const route = useRoute();

    const headerTitle = computed(() => {
      return route.meta.title || 'CEO Discovery';
    });

    const footerText = computed(() => {
      const currentYear = new Date().getFullYear();
      return `\u00A9 CEO Discovery ${currentYear}. All Rights Reserved.`;
    });

    useHead({
      title: headerTitle.value,
      meta: [
        {
          name: 'description',
          content: route.meta.description
        }
      ],
      link: [
        //{rel: 'stylesheet',href: 'https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css'},
        {rel: 'icon',href: ceoDiscoverySymbol,type: 'image/svg+xml'}
      ]
    });

    return {
      headerTitle,
      footerText
    };
  }
});
</script>

<style>

</style>
