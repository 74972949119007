<template>
  <div>
    <div class="draggable-container-fixed search-container" ref="draggableContainer">
      <div class="container-header" ref="containerHeader">
        <span class="fixed-title">Search Container</span>
      </div>
      <input type="text" placeholder="Search items..." class="search-bar">
      <div class="inner-draggable-container"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TestPage',
  mounted() {
    const draggableContainer = this.$refs.draggableContainer;
    const containerHeader = this.$refs.containerHeader;
    let isDragging = false;
    let offsetX, offsetY;
    const onMouseMove = (e) => {
      if (isDragging) {
        draggableContainer.style.left = `${e.clientX - offsetX}px`;
        draggableContainer.style.top = `${e.clientY - offsetY}px`;
      }
    };
    const onMouseUp = () => {
      isDragging = false;
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };
    const onMouseDown = (e) => {
      isDragging = true;
      offsetX = e.clientX - draggableContainer.offsetLeft;
      offsetY = e.clientY - draggableContainer.offsetTop;
      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
    };
    containerHeader.addEventListener('mousedown', onMouseDown);
  }
};
</script>

<style>

</style>
