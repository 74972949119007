<template>
  <v-data-table-server
    :items-per-page="itemsPerPage"
    :headers="headers"
    :items="serverItems"
    :items-length="totalItems"
    :loading="loading"
    :search="search"
    item-value="name"
    @update:options="loadItems"
  ></v-data-table-server>
</template>

<script>
import { defineComponent, ref, getCurrentInstance, watchEffect} from 'vue';
import { useQuery } from '@urql/vue';
import { GET_MY_FIRMS } from '../graphql/firms_queries';

export default defineComponent({
  name: "AppMyFirms",
  setup() {
    const { appContext } = getCurrentInstance();
    const username = appContext.config.globalProperties.$username;

    const itemsPerPage = ref(10);
    const headers = ref([
      { title: 'Firm ID', value: 'id' },
      { title: 'Firm Name', value: 'name' },
      //{ title: 'Total', value: 'totalCount' },
    ]);
    const search = ref('');
    const serverItems = ref([]);
    const loading = ref(true);
    const totalItems = ref(0);
    const page = ref(1);
    //const sortBy = ref([]);

    const loadItems = () => {
      loading.value = true;
      const queryVariables = {
        username: username,
      };
      const { data, error } = useQuery({
        query: GET_MY_FIRMS,
        variables: queryVariables,
      });

      watchEffect(() => {
        if (data.value) {
          serverItems.value = data.value.appFirmSearchCounts;
          totalItems.value = serverItems.value.length;
          loading.value = false;
        }
        if (error.value) {
          console.error('Error fetching data:', error.value);
          loading.value = false;
        }
      });
    };

    return {
      itemsPerPage,
      headers,
      search,
      serverItems,
      loading,
      totalItems,
      page,
      loadItems,
    };
  },
});
</script>
