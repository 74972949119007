<!-- src/views/HomePage.vue -->
<template>
  <div>
    <h1>Welcome to Hal App</h1>
    <p>This is an in-house project designed to help our team streamline tasks and expedite work processes efficiently.</p>
  </div>
</template>
<script>
export default {
  name: 'HomePage',
  data() {
    return {
      
    };
  },
  mounted() {
    // Access the DOM after the component is mounted
    document.getElementById('header-title').innerText = ``;
  }
};
</script>
