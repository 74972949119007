<template>
  <div>
    <div class="date-range pb-2">
      Selected Dates: <strong>{{ weeklyStartDate }} - {{ weeklyEndDate }}</strong> &nbsp;
      <a href="#" @click.prevent="showModal">Change</a>
    </div>
    <!-- Listen for the 'close-modal' event and handle it with the closeModal method -->
    <vue-final-modal v-model="isModalVisible">
      <template #default>
        <AppWeeklyEmailDateRange :isReload="true" @date-selected="handleDateSelected" @close-modal="closeModal" />
      </template>
    </vue-final-modal>
  </div>
</template>

<script>
import { VueFinalModal } from 'vue-final-modal';
import AppWeeklyEmailDateRange from '@/views/AppWeeklyEmailDateRange.vue';

export default {
  name: 'WeeklyDateRangeDisplay',
  components: {
    VueFinalModal,
    AppWeeklyEmailDateRange,
  },
  props: {
    isReload: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isModalVisible: false,
      weeklyStartDate: sessionStorage.getItem('weeklyStartDate') || '',
      weeklyEndDate: sessionStorage.getItem('weeklyEndDate') || ''
    };
  },
  methods: {
    showModal() {
      this.isModalVisible = true;
    },
    handleDateSelected(startDate, endDate) {
      //console.log("HELLO CALLED");
      this.weeklyStartDate = startDate;
      this.weeklyEndDate = endDate;
      sessionStorage.setItem('weeklyStartDate', startDate);
      sessionStorage.setItem('weeklyEndDate', endDate);
      this.isModalVisible = false;
      if (this.isReload) {
        window.location.reload();
      }
    },
    closeModal() {
      // Log a message and close the modal
      //console.log("Modal closed");
      this.weeklyStartDate = sessionStorage.getItem('weeklyStartDate')
      this.weeklyEndDate = sessionStorage.getItem('weeklyEndDate');
      this.isModalVisible = false;
    }
  }
};
</script>
