<template>
  <div>
    <div>Select Date Range</div>
    <date-picker v-model="value" range format="yyyy-MM-dd" :default-value="defaultDateRange" @change="handleDateChange">
      <template v-slot:content>
        <calendar-panel :value="innerValue" :get-classes="getClasses" @pick="handleSelect"></calendar-panel>
      </template>
    </date-picker>
    <div v-if="value.length === 2" class="selected-dates">
      <p>Selected Date Range: {{ this.formatDate(value[0]) }} - {{ this.formatDate(value[1]) }}</p>
    </div>
    <button @click="goToNextPage">Submit</button>
  </div>
</template>

<script>
import DatePicker from "vue-datepicker-next";
import "vue-datepicker-next/index.css";
//import { format, subDays } from 'date-fns';
import { format, subDays, parseISO } from 'date-fns';
const { Calendar: CalendarPanel } = DatePicker;

function isValidDate(date) {
  return date instanceof Date && !isNaN(date);
}

export default {
  name: 'AppWeeklyEmailDateRange',
  components: {
    DatePicker,
    CalendarPanel,
  },
  props: {
    isReload: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const today = new Date();
    const lastWeek = subDays(today, 7);
    let weeklyStartDate;
    let weeklyEndDate;

    if (sessionStorage.getItem('weeklyStartDate')) {
      weeklyStartDate = parseISO(sessionStorage.getItem('weeklyStartDate'));
      weeklyEndDate = parseISO(sessionStorage.getItem('weeklyEndDate'));
    } else {
      weeklyStartDate = lastWeek;
      weeklyEndDate = today;
    }
    return {
      value: [weeklyStartDate, weeklyEndDate],
      innerValue: [weeklyStartDate, weeklyEndDate],
      defaultDateRange: [lastWeek, today],
    };
  },
  methods: {
    getClasses(cellDate, currentDates, classes) {
      if (
        !/disabled|active|not-current-month/.test(classes) &&
        currentDates.length === 2 &&
        cellDate.getTime() > currentDates[0].getTime() &&
        cellDate.getTime() < currentDates[1].getTime()
      ) {
        return "in-range";
      }
      return "";
    },
    handleSelect(date) {
      const [startValue, endValue] = this.innerValue;
      if (isValidDate(startValue) && !isValidDate(endValue)) {
        if (startValue.getTime() > date.getTime()) {
          this.innerValue = [date, startValue];
        } else {
          this.innerValue = [startValue, date];
        }
        this.value = this.innerValue.map(date => format(date, 'yyyy-MM-dd'));
      } else {
        this.innerValue = [date, new Date(NaN)];
      }
    },
    handleDateChange(dates) {
      if (Array.isArray(dates) && dates.length === 2) {
        this.innerValue = dates.map(date => new Date(date));
        this.value = dates.map(date => format(new Date(date), 'yyyy-MM-dd'));
      }
    },
    goToNextPage() {
      
      //sessionStorage.setItem('weeklyStartDate', this.value[0]);
      //sessionStorage.setItem('weeklyEndDate', this.value[1]);
      sessionStorage.setItem('weeklyStartDate', this.formatDate(this.value[0]));
      sessionStorage.setItem('weeklyEndDate', this.formatDate(this.value[1]));

      // Check if the current route is 'AppWeeklyEmailDateRange'
      if (this.$route.name === 'AppWeeklyEmailDateRange') {
        // Navigate to 'EmailsWeeklyEmails' if the current route is 'AppWeeklyEmailDateRange'
        this.$router.push({
          name: 'EmailsWeeklyEmails',
        });
      } else {
        // Emit 'close-modal' event if the current route is not 'AppWeeklyEmailDateRange'
        this.$emit('close-modal');
        if (this.isReload) {
          window.location.reload();
        }
        //console.log("CLOSE ME");
      }
    },
    formatDate(date) {
      return format(date, 'yyyy-MM-dd');
    }
  },
};
</script>


<style scoped>
/* Add your styles here */
.selected-dates {
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
</style>
