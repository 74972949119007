<template>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li 
        v-for="(crumb, index) in crumbs" 
        :key="index" 
        :class="['breadcrumb-item', { active: index === crumbs.length - 1 }]"
        :aria-current="index === crumbs.length - 1 ? 'page' : null"
      >
        <!-- Use router-link if routerName is provided and it's not the last breadcrumb -->
        <router-link 
          v-if="crumb.routerName && index !== crumbs.length - 1" 
          :to="{ name: crumb.routerName, params: crumb.params || {}, query: crumb.query || {} }"
        >
          {{ crumb.text }}
        </router-link>
        <!-- Use an anchor tag if a direct link is provided and it's not the last breadcrumb -->
        <a 
          v-else-if="crumb.link && index !== crumbs.length - 1" 
          :href="crumb.link"
        >
          {{ crumb.text }}
        </a>
        <!-- Display as plain text if it's the last breadcrumb or no link/routerName is provided -->
        <span v-else>{{ crumb.text }}</span>
      </li>
    </ol>
  </nav>
</template>

<script>
export default {
  name: 'BreadcrumbNav',
  props: {
    crumbs: {
      type: Array,
      required: true,
      validator(value) {
        return value.every(crumb => 'text' in crumb && ('link' in crumb || 'routerName' in crumb));
      }
    }
  }
}
</script>

<style scoped>
.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
}
</style>
