<template>
  <div>
      <WeeklyDateRangeDisplay :isReload="false" />
      <div v-for="firm in serverItems" :key="firm.firm_id" class="row align-items-center mb-1 p-2 bg-light">
        <div class="col text-left">
          <router-link :to="{ name: 'EmailFirmSearchResults', params: { firmId: firm.id } }">
            {{ firm.name }} ({{ firm.totalCount }})
          </router-link>
        </div>
      </div>
  </div>
</template>
<script>
import { defineComponent, ref, getCurrentInstance, watchEffect} from 'vue';
import { useQuery } from '@urql/vue';
import { GET_MY_FIRMS } from '../graphql/firms_queries';
import sessionCheckMixin from '../mixins/CheckWeeklyDateSession';
import WeeklyDateRangeDisplay from '../components/WeeklyDateRangeDisplay.vue';

export default defineComponent({
  name: "EmailsWeeklyEmails",
  components: {
    WeeklyDateRangeDisplay,
  },
  mixins: [sessionCheckMixin],
  setup() {
    const { appContext } = getCurrentInstance();
    const username = appContext.config.globalProperties.$username;

    const serverItems = ref([]);
    const loading = ref(true);
    const totalItems = ref(0);
    
    loading.value = true;
    const { data, error } = useQuery({
      query: GET_MY_FIRMS,
      variables: {username: username},
    });
    watchEffect(() => {
      if (data.value) {
        serverItems.value = data.value.appFirmSearchCounts;
        totalItems.value = serverItems.value.length;
        loading.value = false;
      }
      if (error.value) {
        console.error('Error fetching data:', error.value);
        loading.value = false;
      }
    });

    return {      
      serverItems,
      loading,
      totalItems,
    };
  },
});
</script>