import gql from 'graphql-tag';

export const GET_MY_FIRMS = gql`
  query GetMyFirms($username: String!) {
    appFirmSearchCounts(username: $username) {
      id
      name
      totalCount
    }
  }
`;

export const APP_FIRM_SEARCH_RESULTS_BY_FIRM_ID = gql`
  query appFirmSearchResultsByFirmId($firm_id: String!) {
    appFirmSearchResultsByFirmId(firmId: $firm_id) {  
      firmName
      userIdToName {
        name {
          fullName
        }
        experienceAggregate {
          currentTitle
          companyName
        }
        id
      }
      searchRecords {
        name
        id
        excludedIds
        searchedParameters {
          executive {
            experience {
              highestTitle
              plSize
            }
            industrySectors
            willingToRelocate
            expertise {
              addonAcquisitionIntegration
              addonAcquisitionIntegrationRoles
              addonAcquisitions
              addonAcquisitionsRoles
              financialRestruct
              financialRestructRoles
              highestLevel
              hyperGrowth
              turnAroundRoles
              turnAround
              sponsored
              salesProcessRoles
              salesProcess
              operationalTurnaroundRoles
              operationalTurnaround
              mergersAcquisitionsRoles
              mergersAcquisitions
              ipoProcessRoles
              ipoProcess
              hyperGrowthRoles
            }
          }
        }
        savedBy {
          userId
          firmId
          role
        }
      }
    }
  }
`;