<template>
  <div class="w-100">
    <BreadcrumbNav :crumbs="breadcrumbs" />
    <WeeklyDateRangeDisplay :isReload="true" />
    <button class="btn btn-primary" @click="hideSearchFormModal">Search Form</button>
    <div v-if="loading">Loading...</div>
    <div v-if="error">Error: {{ error.message }}</div>
    <div class="outer-container">
      <!-- Draggable List of Containers -->
      <draggable
        v-model="containers"
        :group="'outer-group'"
        class="outer-draggable-container"
        :animation="200"
        @start="onStart"
        @end="onEnd"
        item-key="id"
      >
        <template #item="{ element }">
          <div :key="element.id" class="container">
            <div class="container-header">
              <input
                v-model="element.title"
                @input="editTitle(element.id, element.title)"
                class="title-input"
              />
            </div>
            <!--<div>Total: {{ element.total_items }}</div>-->
            <draggable
              v-model="element.items"
              :group="'inner-group'"
              class="inner-draggable-container"
              :animation="200"
              @start="onStart"
              @end="onEnd"
              item-key="id"
            >
              <template #item="{ element: itemElement }">
                <div :key="itemElement.id" class="draggable-item" :data-id="itemElement.id">
                  {{ itemElement.name }} ({{ itemElement.title }})
                  <a :href="itemElement.link" class="icon-link" target="_blank"><i class="fas fa-link"></i></a>
                </div>
              </template>
              
              <template #footer>
                <button @click="showAllChildren(element.id)">Search Filter</button>
              </template>
            </draggable>
          </div>
        </template>
      </draggable>
      
      <!-- Non-Draggable Search Container with Fixed Title -->
      <div :class="{ visible: showSearchFormModal, hidden: !showSearchFormModal }" class="draggable-container-fixed search-container" ref="draggableContainer">
        <div class="container-header" ref="containerHeader"><span class="fixed-title">Search Container</span></div>
        <input type="text" v-model="searchQuery" placeholder="Search items..."  class="search-bar" />
        <draggable
          v-model="filteredItems"
          :group="'inner-group'"
          class="inner-draggable-container"
          :animation="200"
          @start="onStart"
          @end="onEnd"
          item-key="id"
        >
          <template #item="{ element: itemElement }">
            <div :key="itemElement.id" class="draggable-item">
              {{ itemElement.name }}
              <a :href="itemElement.link" class="icon-link" target="_blank"><i class="fas fa-link"></i></a>
            </div>
          </template>
        </draggable>
      </div>
    </div>
    
    <button class="btn btn-primary" @click="handlePreviewEmail">Preview Email</button>
    <VueFinalModal v-model="showModal" content-class="custom-modal-content">
      <button class="close-button" @click="showModal = false"><i class="fas fa-times"></i></button>
      <div ref="emailContent">
        <div style="background-color: #f0f0f0; padding: 20px; font-family: 'Times New Roman', Times, serif; font-size: 17px; line-height: 1.2;">
            <div style="position: relative; padding-bottom:20px; background-color: white; max-width: 600px; margin: 0 auto; border: 1px solid #ccc; box-sizing: border-box;">
              <div style="padding: 20px 20px 0px 20px">
                <div style="text-align: center;padding-bottom: 20px;"><img src="@/assets/images/CEODiscovery_Logo.jpg" alt="CEO Discovery Logo" style="width: 300px; height: auto;" /></div>
                <p>{{ profile_name }}:</p>
                <p style="">The following executives have recently been added to the CEO Discovery platform who match your criteria below:<br/></p>
              </div>
              <div v-for="(container, index) in containersCopy" :key="container.id">
                <div v-if="index === 0">
                  <div v-for="(item, subIndex) in duplicates" :key="item.id">
                    <table border="0" cellpadding="0" cellspacing="0" style="width: 100%;">
                      <tr v-if="subIndex === 0">
                        <td colspan="3" style="background-color:#edf1f7; font-size: 19px; padding:20px 20px 10px 20px;"><strong>Executives Matching Multiple Lists</strong></td>
                      </tr>
                      <tr>
                        <td colspan="3" style="background-color:#edf1f7; vertical-align: bottom; padding:0px 20px 0px 20px;"><a :href="item.link" target="_blank" style="color:#325ac6"><strong>{{ item.full_name }}</strong></a></td>
                      </tr>
                      <tr>
                        <td style="background-color:#edf1f7; vertical-align: top; padding:0px 10px 0px 20px; width: 250px;"><strong>{{ item.title }}, {{ item.company_name }}</strong></td>
                        <td style="background-color:#edf1f7; white-space: nowrap; text-align: right; padding:0px 10px 0px 10px; width: 150px">PE Experience: {{ item.sponsored ? 'Yes' : 'No' }}</td>
                        <td style="background-color:#edf1f7; white-space: nowrap; text-align: left; padding:0px 20px 0px 10px; width: 150px">P&L: {{ item.pl_size }}</td>
                      </tr>
                      <tr>
                        <td colspan="3" style="background-color:#edf1f7; padding:0px 20px 20px 20px;"><span v-if="item.industry_sectors_compressed">Sector Experience: {{ item.industry_sectors_compressed }}</span><span v-else>Sector Experience: {{ item.industry_sectors }}</span></td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div v-for="(item,item_index) in container.items" :key="item.id">
                  <table border="0" cellpadding="0" cellspacing="0" style="width: 100%;">
                    <tr v-if="item_index === 0">
                      <td colspan="3" style="vertical-align: bottom;"><div style="font-size: 19px; margin-top: 0px;padding-top: 20px;margin-left: 0px;padding-left: 20px;padding-bottom: 0px;"><div v-if="container.title"><strong>{{ container.title }}</strong></div></div></td>
                    </tr>
                    <tr>
                      <td colspan="3" style="vertical-align: bottom; padding:10px 20px 0px 20px;"><a :href="item.link" target="_blank" style="color:#325ac6"><strong>{{ item.full_name }}</strong></a></td>
                    </tr>
                    <tr>
                      <td style="vertical-align: top; padding:0px 10px 0px 20px; width: 250px;"><strong>{{ item.title }}, {{ item.company_name }}</strong></td>
                      <td style="white-space: nowrap; text-align:right; padding:0px 10px 0px 10px; width: 150px;">PE Experience: {{ item.sponsored ? 'Yes' : 'No' }}</td>
                      <td style="white-space: nowrap; text-align: left; padding:0px 20px 0px 10px; width:150px"><span v-if="item.pl_size">P&L: {{ item.pl_size }}</span></td>
                    </tr>
                    <tr>
                      <td colspan="3" style="padding:0px 20px;"><span v-if="item.industry_sectors_compressed">Sector Experience: {{ item.industry_sectors_compressed }}</span><span v-else>Sector Experience: {{ item.industry_sectors }}</span></td>
                    </tr>
                  </table>
                </div>
                <div v-if="index !== containersCopy.length - 1 && container.items.length > 0" style="text-align: center; padding-top: 20px;"><hr style="width: 70%; border: none; border-top: 1px solid gray; margin: 0 auto;" /></div>
              </div>
              <div style="padding-left: 20px;"><br/><br/><br/></div>
            </div>
        </div>
      </div>
      <button class="btn btn-primary" @click="copyEmailContent">Copy Email</button>
    </VueFinalModal>

    <!-- All Children Modal -->
    <div v-if="showAllChildrenModal" :class="showAllChildrenModal ? 'modal modal-show' : 'modal'">
      <div class="modal-content">
        <span class="close" @click="hideModals">&times;</span>
        <h5>Search Filter</h5>
        <pre>{{ childrenData }}</pre>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, getCurrentInstance, onMounted, watchEffect } from 'vue';
import { useRoute } from 'vue-router';
import draggable from 'vuedraggable';
import WeeklyDateRangeDisplay from '../components/WeeklyDateRangeDisplay.vue';
import BreadcrumbNav from '@/components/BreadCrumbNav.vue';
import sessionCheckMixin from '../mixins/CheckWeeklyDateSession';
import { useQuery } from '@urql/vue';
import { VueFinalModal } from 'vue-final-modal';
import { APP_GET_PROFILE_BY_NAME, APP_GET_EXECUTIES_SAVE_SEARCHS_BY_PROFILE_ID } from '../graphql/search_criteria_list';

export default defineComponent({
  name: 'EmailFirmSearchExecutives',
  mixins: [sessionCheckMixin],
  components: {
    VueFinalModal,
    draggable,
    WeeklyDateRangeDisplay,
    BreadcrumbNav
  },
  setup() {
    // Modal visibility
    const showModal = ref(false);
    // Reference to email content for copying
    const emailContent = ref(null);
    const route = useRoute();
    const showAllChildrenModal = ref(false);
    const showSearchFormModal = ref(false);
    const childrenData = ref({});
    // Router and route parameters
    const profile_id = ref('');
    const profile_name = ref('');
    const firm_id = ref('');
    const firm_name = ref('');

    // Containers for draggable items
    const containers = ref([]);
    const containersCopy = ref([]);
    const duplicates = ref([]);

    const searchItems = ref([]);
    const searchQuery = ref('');
    const filteredItems = ref([]);
    const totalItems = ref([]);

    // Breadcrumbs
    const breadcrumbs = ref([]);

    // Loading and error states
    const loading = ref(false);
    const error = ref(null);

    // App context and proxy
    const { appContext } = getCurrentInstance();
    const appProfileURL = appContext.config.globalProperties.$AppProfileURL;
    const variables = ref({ search_name_query: searchQuery.value });
    
    // Duplicate checking function
    const handlePreviewEmail = () => {
      // Step 1: Make a copy of the original containers
      containersCopy.value = JSON.parse(JSON.stringify(containers.value));

      // Reset duplicates array and seenIds set
      duplicates.value = [];
      let seenIds = new Set();

      // Step 2: First loop - Identify duplicates without removing them yet
      containersCopy.value.forEach((container) => {
        container.items.forEach((item) => {
          if (seenIds.has(item.id)) {
            // If the ID is already seen, it's a duplicate
            duplicates.value.push(item);
          } else {
            // Mark the ID as seen
            seenIds.add(item.id);
          }
        });
      });
      // Step 3: Second loop - Remove the duplicates from each container
      containersCopy.value.forEach((container) => {
        container.items = container.items.filter(item => !duplicates.value.some(dup => dup.id === item.id));
      });

      // Step 4: Third loop - Remove containers where items are empty
      containersCopy.value = containersCopy.value.filter(container => container.items.length > 0);
      // Show the modal with duplicates
      showModal.value = true;
    };

    const showAllChildren = (containerId) => {
      const record = containers.value.find(item => item.id === containerId);
      if (record) {
        childrenData.value = record.complete_record.complete_record;
        //console.log("CONTAINER Record:", childrenData.value);
        showAllChildrenModal.value = true;
      }
    };
    const hideModals = () => {
      showAllChildrenModal.value = false;
    };
    const hideSearchFormModal = () => {
      showSearchFormModal.value = !showSearchFormModal.value;
    };
  
    //Fetch search items based on query
    const { data: data_profile, error: profileError, executeQuery: executeProfileQuery } = useQuery({
      query: APP_GET_PROFILE_BY_NAME,
      variables: variables.value,
      pause: true, // Prevent automatic execution
    });
    watchEffect(() => {
      if (searchQuery.value) {
        variables.value.search_name_query = searchQuery.value.trim().toLowerCase();
        executeProfileQuery();
      }
    });

    watchEffect(() => {
      if (data_profile.value) {
        if (Array.isArray(data_profile.value.appGetProfileByName)) {
          searchItems.value = data_profile.value.appGetProfileByName.map(transformItem);
          // Filter items directly
          const query = searchQuery.value.trim().toLowerCase();
          filteredItems.value = query
            ? searchItems.value.filter(item => item.full_name.toLowerCase().includes(query))
            : searchItems.value;
        } else {
          searchItems.value = []; // Clear the search items if the response format is unexpected
        }
      }
      if (profileError.value) {
        searchItems.value = []; // Clear the search items if there is an error
      }
    });
    
    // ---------------------
    
    const transformItem = (item) => {
      //console.log("Naem: ",item.name.fullName);
      //console.log("industry_sectors",item.industrySectors);
      //console.log("industrySectorsCompressed",item.industrySectorsCompressed);
      return {
        ...item,
        id: item.id, // Use `id` instead of `_id`
        title: item.experienceAggregate ? item.experienceAggregate.title || '' : '', // Handle potential null values
        full_name: item.name.fullName, // Corrected the casing for `fullName`
        company_name: item.experienceAggregate ? item.experienceAggregate.companyName || '' : '',
        pl_size: item.experienceAggregate ? item.experienceAggregate.plSizeCompressed || '' : '',
        pe_expertise: item.experienceAggregate ? item.experienceAggregate.plResponsibility : false, // Corrected the field for PE expertise
        industry_sectors_compressed: item.industrySectorsCompressed ? item.industrySectorsCompressed.join(', ') : '',
        industry_sectors: item.industrySectors ? item.industrySectors.join(', ') : '',
        pl_responsibility: item.experienceAggregate ? item.experienceAggregate.plResponsibility : false, // Added `pl_responsibility` field
        sponsored: item.expertise ? item.expertise.sponsored : false, // Added `pl_responsibility` field
        name: `${item.name.fullName}, ${item.experienceAggregate && item.experienceAggregate.title ? item.experienceAggregate.title : ''} @ ${item.experienceAggregate && item.experienceAggregate.companyName ? item.experienceAggregate.companyName : ''}`,
        link: `${appProfileURL}${item.id}` // Updated to use `id` instead of `_id`
      };
    };

    // Copy email content to clipboard
    const copyEmailContent = () => {
      if (emailContent.value) {
        const content = emailContent.value.innerHTML;
        const type = 'text/html';
        const blob = new Blob([content], { type });
        const data = [new ClipboardItem({ [type]: blob })];
        navigator.clipboard.write(data).then(() => {
          alert('Email content copied to clipboard!');
        }).catch(err => {
          console.error('Failed to copy: ', err);
        });
      }
    };

    // Edit title of a container
    const editTitle = (id, newTitle) => {
      const container = containers.value.find(c => c.id === id);
      if (container) {
        container.title = newTitle;
      }
    };

    // Drag start event handler
    const onStart = (evt) => {
      console.log('Drag started:', evt);
    };
    // Drag end event handler
    const onEnd = (evt) => {
      console.log('Drag ended:', evt);
    };

    // Fetch search results for the main data
    const fetchSearchResults = () => {
      const weeklyStartDate = sessionStorage.getItem('weeklyStartDate');
      const weeklyEndDate = sessionStorage.getItem('weeklyEndDate');
      const { data, error, executeQuery } = useQuery({
        query: APP_GET_EXECUTIES_SAVE_SEARCHS_BY_PROFILE_ID,
        variables: { profile_id: profile_id.value, weeklyStartDate, weeklyEndDate }
      });

      watchEffect(() => {
        if (data.value) {
          const searchResults = data.value.appGetExecutiesSaveSearchsByProfileId.searchRecords;
          firm_name.value = data.value.appGetExecutiesSaveSearchsByProfileId.firmName;
          firm_id.value = data.value.appGetExecutiesSaveSearchsByProfileId.profileData.firmId;
          profile_name.value = data.value.appGetExecutiesSaveSearchsByProfileId.profileData.name.fullName.split(' ')[0];
          document.getElementById('header-title').innerText = `Weekly Emails - ${firm_name.value}`;
          totalItems.value = searchResults.length;
          loading.value = false;

          breadcrumbs.value = [
            { text: 'Home', routerName: 'HomePage' },
            { text: 'Weekly Emails', routerName: 'EmailsWeeklyEmails' },
            { text: `Weekly Emails - Search Results - ${firm_name.value}`, routerName: 'EmailFirmSearchResults', params: { firmId: firm_id.value } },
            { text: `Search Executives for ${profile_name.value}`, routerName: '' },
          ];

          containers.value = searchResults.map(record => {            
            return {
              id: record.id,
              title: record.name,
              pl_size: record.searchedParameters.executive.experience.plSizeCompressed,
              industry_sectors: record.searchedParameters.executive.industrySectors,
              industry_sectors_compressed: record.searchedParameters.executive.industrySectorsCompressed,
              items: [],
              total_items: 0,
              complete_record: record
            };
          });

          const executivesData = data.value.appGetExecutiesSaveSearchsByProfileId.executives;
          //console.log("executivesData",executivesData);
          executivesData.forEach(executiveData => {
            const container = containers.value.find(c => c.id === executiveData.searchId);
            if (container) {
              container.items = executiveData.executivesData.map(transformItem);
              container.total_items = container.items.length; 
            }
          });
        }
        //remove the proxy
        const rawContainers = containers.value.map(container => ({
          id: container.id,
          title: container.title,
          //pl_size: [...container.pl_size], // Unwrap the Proxy
          pl_size: container.pl_size, // Unwrap the Proxy
          industry_sectors: [...container.industry_sectors], // Unwrap the Proxy
          industry_sectors_compressed: [...container.industry_sectors_compressed], // Unwrap the Proxy
          complete_record: container,
          items: container.items.map(item => ({
            ...item,
            name: item.name,
            id: item.id
          }))
        }));

        // Now assign this flattened data to your containers
        containers.value = rawContainers;
        
        if (error.value) {
          console.error('Error fetching data:', error.value);
          loading.value = false;
        }
      });

      executeQuery();
    };

    // Mounted lifecycle hook
    onMounted(() => {
      profile_id.value = route.params.profile_id;
      fetchSearchResults(); // Fetch the initial data when the component mounts

      
      // Draggable container setup
      const draggableContainer = document.querySelector('.draggable-container-fixed');
      const containerHeader = document.querySelector('.container-header');

      let isDragging = false;
      let offsetX, offsetY;

      const onMouseMove = (e) => {
        if (isDragging) {
          draggableContainer.style.left = `${e.clientX - offsetX}px`;
          draggableContainer.style.top = `${e.clientY - offsetY}px`;
        }
      };

      const onMouseUp = () => {
        isDragging = false;
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);
      };

      const onMouseDown = (e) => {
        isDragging = true;
        offsetX = e.clientX - draggableContainer.offsetLeft;
        offsetY = e.clientY - draggableContainer.offsetTop;
        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);
      };

      containerHeader.addEventListener('mousedown', onMouseDown);
      
    });

    return {
      showModal,
      emailContent,
      profile_name,
      copyEmailContent,
      containers,
      containersCopy,
      duplicates,
      searchQuery,
      filteredItems,
      editTitle,
      onStart,
      onEnd,
      profile_id,
      firm_id,
      firm_name,
      breadcrumbs,
      loading,
      error,
      childrenData,
      showAllChildrenModal,
      showAllChildren,
      hideModals,
      showSearchFormModal,
      hideSearchFormModal,
      handlePreviewEmail
    };
  },
  created() {
    // Check session dates on component creation
    this.checkSessionDates();
  }
});
</script>
<style scoped>
.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
}

.visible {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.5s linear;
}
</style>
